/* You can add global styles to this file, and also import other style files */

html, body {
    height: 100%;
    background-color: var(--ktd-background-color-step-50);
}

body {
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
}

a {
    color: #ffa726;
}

table {
    width: 100%;
    text-align: center !important;
}

.mat-sort-header-container{
    justify-content: center;
}

.mat-header-cell {
    text-align: center !important;
}